.container{
    width: 800px;
    box-shadow: 0px 0px 15px 5px #b4b4b4;
    padding-bottom: 60px;
}

.title{
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
    margin-top: 60px;
    margin-left: 40px;
    margin-right: 40px;
}

.text{
    font-size: 14px;
    color: rgb(70, 70, 70);
    white-space: pre-wrap;
    margin-left: 40px;
    margin-right: 40px;
}

@media (max-width: 800px){
    .container{
        width: 100vw;
    }

    .title{
        margin-left: 20px;
        margin-right: 20px;
        text-align: center;
    }

    .text{
        margin-left: 20px;
        margin-right: 20px; 
    }
}

@media (max-width: 500px){
    .title{
        font-size: 16px;
    }

    .text{
        font-size: 12px;
    }
}