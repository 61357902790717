$icon: 40px;

.container{
    height: 100px;
    width: 1000px;
    background-color: #eaebec;
    margin-top: 100px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
}

.img{
    height: $icon;
    width: $icon;
    border-radius: 50%;
    margin-left: 15px;
    margin-right: 15px;
    cursor: pointer;
}

@media (max-width: 1000px){
    .container{
        width: 100vw;
    }
}